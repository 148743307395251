import React, {PureComponent} from 'react'
import labels from './../../translations'
import {getFormatNum, getTotalsAndVat} from "../../utils/invoice";

export class InvoiceEntries extends PureComponent {

  render() {

    const {entries, invoiceMeta} = this.props
      const {total, totalVat, vatAmount, vatBasis} = getTotalsAndVat(entries, invoiceMeta)
    return (
      <table className="Invoice-table Table-wide">
        <thead>
        <tr>
          {/*<th>{labels.date}</th>*/}
            <th>No.</th>
          <th>{labels.description}</th>
          <th>{labels.qty}</th>
          <th>{labels.rate}</th>
          <th>{labels.total}</th>
        </tr>
        </thead>
        <tbody>
        {entries?.map((entry, index) => {
          return (
            <tr key={`entry_${index}`}>
{/*              <td className="Col-date">{entry.dateProvided}</td>*/}
                <td width="50px">{index+1}</td>
              <td className="Col-description">{entry.description}</td>
              {/*<td className="Col-qty text-right">{entry.qty}{entry.qtyType}</td>*/}
                <td className="Col-rate">{getFormatNum(entry.qty)}</td>
                <td className="Col-rate">{getFormatNum(entry.rate)}</td>
              {/*<td className="Col-rate">{invoiceMeta.currency}{entry.rate}{entry.qtyType && `/${entry.qtyType}`}</td>*/}
              {/*<td className="Col-total">{invoiceMeta.currency}{entry.total}</td>*/}
                <td className="Col-total">{getFormatNum(entry.total)}</td>
            </tr>
          )
        })}
        <tr>
            <td colspan="5" className="Col-total">{invoiceMeta.currency} {getFormatNum(parseFloat(total).toFixed(0))}</td>
        </tr>

        </tbody>
      </table>
    )
  }
}

export default InvoiceEntries
